<template>
  <transition mode="out-in"
              name="fade">

    <base-icon-button-send v-if="isConfirmation"
                           :key="btnOptions.confirmation.button.key"
                           v-on="$listeners"
                           :icon-attrs="btnOptions.confirmation.icon"
                           :title="btnOptions.confirmation.button.title"/>

    <base-icon-button-send v-else
                           :key="btnOptions.attempt.button.key"
                           :icon-attrs="btnOptions.attempt.icon"
                           :title="btnOptions.attempt.button.title"/>

  </transition>
</template>

<script>
  import BaseIconButtonSend from '@/components/baseComponents/baseIcons/BaseIconButtonSend';

  export default {
    name: 'BaseDoubleClickSubmitButton',
    components: { BaseIconButtonSend },
    props: {
      isConfirmation: {
        type: Boolean,
        required: true,
      },
      btnOptions: {
        type: Object,
        required: true,
      },
      iconText: {
        type: String,
        default: 'send',
      },
    },
  };
</script>

<style lang="scss"
       scoped>
  .cursor-pointer {
    cursor: pointer
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>
